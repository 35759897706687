





























import { Component, Vue } from 'vue-property-decorator';

import StartButton from '@/components/StartButton.vue';
import {
  dispatchCancelGoogleSearch,
  dispatchGetGoogleSearchStatus,
  dispatchRunGoogleSearch,
} from '@/store/google-search/actions';
import { readGoogleSearchFinished, readGoogleSearchState } from '@/store/google-search/getters';
import { commitSetGoogleSearchFinished } from '@/store/google-search/mutations';
import { commitAddNotification } from '@/store/main/mutations';
import { delay } from '@/utils';

const GS_STATUS_ACTIVE = 1;
const GS_STATUS_INERVAL = 5000; // 5 seconds

@Component({
  components: {
    StartButton,
  },
})
export default class GoogleSearch extends Vue {
  public timeOutPointer?: number = undefined;

  get gsState() {
    return readGoogleSearchState(this.$store);
  }

  get isFinished() {
    return readGoogleSearchFinished(this.$store);
  }

  public async handleSendClaims() {
    await dispatchRunGoogleSearch(this.$store);
    this.checkGoogleSearchStatus();
  }

  public setFinished(value = true) {
      commitSetGoogleSearchFinished(this.$store, value);
  }

  public async checkGoogleSearchStatus(cancelled = false) {
    const data = await dispatchGetGoogleSearchStatus(this.$store);
    if (data.status === GS_STATUS_ACTIVE) {
      this.setFinished();
      clearInterval(this.timeOutPointer);
      this.timeOutPointer =  setTimeout(() => this.checkGoogleSearchStatus(), GS_STATUS_INERVAL);
    } else {
      this.gsState.status = null;
      if (this.isFinished) {
        const statusMsg = this.gsState.message ? '. ' + this.gsState.message : '';
        const statMsg = data.total ? `подано ${data.done} претензий из ${data.total}` : '';
        const msg = statMsg + statusMsg;
        const content = cancelled ? 'Google Search: задача отменена' + (msg ? '; ' + msg : '')
          : 'Google Search: ' + msg;
        commitAddNotification(this.$store, { content, color: 'info' });
        this.setFinished(false);
      }
    }
  }

  public async mounted() {
    this.checkGoogleSearchStatus();
  }

  public destroyed() {
    clearInterval(this.timeOutPointer);
  }

  public async handleCancelTask() {
    this.gsState.status = null;
    // используем delay, чтобы бэк успел обновить статус в redis
    const res = await Promise.all([dispatchCancelGoogleSearch(this.$store), delay(500)]);
    if (res) {
      clearInterval(this.timeOutPointer);
      this.checkGoogleSearchStatus(true);
    }
  }
}
